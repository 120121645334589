<template>
    <TableLayout
        :module="module"
        ref="TableLayout"
        :form-initial="form_initial"
        :form-rules="formRules"
        :rights="rights"
        :table-columns="tableColumns"
        v-if="(user && user.rights)"
        initSortField="name"
        initSortOrder="desc"
        :filters="filters"
        addButtonName="Add Custodian">

        <template v-slot:form_items="{formdata}">
            <el-form-item label="Name" prop="name">
                <el-input v-model="formdata.name" maxlength="100" minlength="2" />
            </el-form-item>
            <el-form-item label="Email" prop="email">
                <el-input v-model="formdata.email" />
            </el-form-item>
            <el-form-item label="Address" prop="address">
                <el-input v-model="formdata.address" />
            </el-form-item>
            <el-form-item label="Person" prop="person">
                <el-input v-model="formdata.person" />
            </el-form-item>
        </template>

    </TableLayout>
</template>

<script>
import { mapState } from 'vuex'
import TableLayout from '@/components/TableLayout'
import tab from '@/mixins/tab'

export default {
    name: 'dict_custodians',
    mixins: [tab],
    components: { TableLayout },

    data() {
        return {
            module: 'dictionaryCustodians',
            form_initial: {},
            formRules: {
                name: [
                    { required: true, message: 'this field is required', trigger: 'blur' },
                ]
            },
            filters: {
                name    : { value: undefined },
                email   : { value: undefined },
                address : { value: undefined },
                person  : { value: undefined },
            },
            tableColumns: [
                {
                    field    : 'name',    
                    title    : 'Name',    
                    minWidth : 200,
                    params   : {
                        filter : 'input', 
                    },
                },{
                    field    : 'email',   
                    title    : 'Email',   
                    minWidth : 200,
                    params   : {
                        filter : 'input', 
                    },
                },{
                    field    : 'address', 
                    title    : 'Address', 
                    minWidth : 200,
                    params   : {
                        filter : 'input', 
                    },
                },{
                    field    : 'person',  
                    title    : 'Person',  
                    minWidth : 200,
                    params   : {
                        filter : 'input', 
                    },
                },
            ],
            filters: {
                name    : { value: undefined },
                email   : { value: undefined },
                address : { value: undefined },
                person  : { value: undefined },
            }
        }
    },

    computed: {
        ...mapState({
            user: state => state.app.user,
        }),

        rights(){
            return {
                view : (this.user && this.user.rights) ? this.user.rights['get-portf-settings']   : false,
                edit : (this.user && this.user.rights) ? this.user.rights['edit-portf-settings']  : false,
                add  : (this.user && this.user.rights) ? this.user.rights['add-portf-settings']   : false,
                del  : (this.user && this.user.rights) ? this.user.rights['delete-portf-settings']: false,
            }
        }
    },
}
</script>
